@keyframes show {
    0% {
        opacity: 0;
        transform: scale3d(0.8, 0.8, 0.8);
    }

    50% {
        transform: scale3d(1.05, 1.05, 1.05);
    }

    100% {
        transform: scale3d(1, 1, 1);
    }
}

@keyframes hide {
    0% {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }

    100% {
        opacity: 0;
        transform: scale3d(0.8, 0.8, 0.8);
    }
}

@keyframes grow {
    0% {
        opacity: 0;
        transform: scale3d(0.8, 0.8, 0.8);
    }

    100% {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }
}

@keyframes shrink {
    0% {
        opacity: 1;
        transform: scale3d(1, 1, 1);
    }

    100% {
        opacity: 0;
        transform: scale3d(0.8, 0.8, 0.8);
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.emoji-picker {
    --animation-duration: 0.2s;
    --animation-easing: ease-in-out;
    --emoji-size: 1.8em;
    --emoji-size-multiplier: 1.5;
    --emoji-preview-size: 2em;
    --emoji-per-row: 8;
    --row-count: 6;
    --content-height: calc((var(--emoji-size) * var(--emoji-size-multiplier)) * var(--row-count) + var(--category-name-size) + var(--category-button-height) + 0.5em);
    --category-name-size: 0.85em;
    --category-button-height: 2em;
    --category-button-size: 1.1em;
    --category-border-bottom-size: 4px;
    --focus-indicator-color: #999999;
    --search-height: 2em;
    --blue-color: #4F81E5;
    --border-color: #CCCCCC;
    --background-color: #FFFFFF;
    --text-color: #000000;
    --secondary-text-color: #666666;
    --hover-color: #E8F4F9;
    --search-focus-border-color: var(--blue-color);
    --search-icon-color: #CCCCCC;
    --overlay-background-color: rgba(0, 0, 0, 0.8);
    --popup-background-color: #FFFFFF;
    --category-button-color: #666666;
    --category-button-active-color: var(--blue-color);
    --dark-border-color: #666666;
    --dark-background-color: #333333;
    --dark-text-color: #FFFFFF;
    --dark-secondary-text-color: #999999;
    --dark-hover-color: #666666;
    --dark-search-background-color: #666666;
    --dark-search-border-color: #999999;
    --dark-search-placeholder-color: #999999;
    --dark-search-focus-border-color: #DBE5F9;
    --dark-popup-background-color: #333333;
    --dark-category-button-color: #FFFFFF;
}

.emoji-picker {
    font-size: 16px;
    border: 1px solid var(--border-color);
    border-radius: 5px;
    background: var(--background-color);
    width: calc(var(--emoji-per-row) * var(--emoji-size) * var(--emoji-size-multiplier) + 1em + 1.5rem);
    font-family: Arial, Helvetica, sans-serif;
    overflow: hidden;
    animation: show var(--animation-duration) var(--animation-easing);
}

.emoji-picker.hiding {
    animation: hide var(--animation-duration) var(--animation-easing);
}

.emoji-picker.dark {
    background: var(--dark-background-color);
    color: var(--dark-text-color);
    border-color: var(--dark-border-color);
}

.emoji-picker__content {
    padding: 0.5em;
    height: var(--content-height);
    position: relative;
}

.emoji-picker__preview {
    height: var(--emoji-preview-size);
    padding: 0.5em;
    border-top: 1px solid var(--border-color);
    display: flex;
    flex-direction: row;
    align-items: center;
}

.emoji-picker.dark .emoji-picker__preview {
    border-top-color: var(--dark-border-color);
}

.emoji-picker__preview-emoji {
    font-size: var(--emoji-preview-size);
    margin-right: 0.25em;
    font-family: "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI", "Apple Color Emoji", "Twemoji Mozilla", "Noto Color Emoji", "EmojiOne Color", "Android Emoji";
}

.emoji-picker__preview-emoji img.emoji {
    height: 1em;
    width: 1em;
    margin: 0 .05em 0 .1em;
    vertical-align: -0.1em;
}

.emoji-picker__preview-name {
    color: var(--text-color);
    font-size: 0.85em;
    overflow-wrap: break-word;
    word-break: break-all;
}

.emoji-picker.dark .emoji-picker__preview-name {
    color: var(--dark-text-color);
}

.emoji-picker__container {
    display: grid;
    grid-template-columns: repeat(var(--emoji-per-row), calc(var(--emoji-size) * var(--emoji-size-multiplier)));
    grid-auto-rows: calc(var(--emoji-size) * var(--emoji-size-multiplier));
}

.emoji-picker__container.search-results {
    height: var(--content-height);
    overflow-y: scroll;
}

.emoji-picker__emoji {
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: var(--emoji-size);
    width: 1.5em;
    height: 1.5em;
    padding: 0;
    margin: 0;
    outline: none;
    font-family: "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI", "Apple Color Emoji", "Twemoji Mozilla", "Noto Color Emoji", "EmojiOne Color", "Android Emoji";
}

.emoji-picker__emoji img.emoji {
    height: 1em;
    width: 1em;
    margin: 0 .05em 0 .1em;
    vertical-align: -0.1em;
}

.emoji-picker__emoji:focus, .emoji-picker__emoji:hover {
    background: var(--hover-color);
}

.emoji-picker__emoji:focus {
    outline: 1px dotted var(--focus-indicator-color);
}

.emoji-picker.dark .emoji-picker__emoji:focus, .emoji-picker.dark .emoji-picker__emoji:hover {
    background: var(--dark-hover-color);
}

.emoji-picker__search-container {
    margin: 0.5em;
    position: relative;
    height: var(--search-height);
    display: flex;
}

.emoji-picker__search {
    box-sizing: border-box;
    width: 100%;
    border-radius: 3px;
    border: 1px solid var(--border-color);
    padding-right: 2em;
    padding: 0.5em 2.25em 0.5em 0.5em;
    font-size: 0.85em;
    outline: none;
}

.emoji-picker.dark .emoji-picker__search {
    background: var(--dark-search-background-color);
    color: var(--dark-text-color);
    border-color: var(--dark-search-border-color);
}

.emoji-picker.dark .emoji-picker__search::placeholder {
    color: var(--dark-search-placeholder-color);
}

.emoji-picker__search:focus {
    border: 1px solid var(--search-focus-border-color);
}

.emoji-picker.dark .emoji-picker__search:focus {
    border-color: var(--dark-search-focus-border-color);
}

.emoji-picker__search-icon {
    position: absolute;
    color: var(--search-icon-color);
    width: 1em;
    height: 1em;
    right: 0.75em;
    top: calc(50% - 0.5em);
}

.emoji-picker__search-not-found {
    color: var(--secondary-text-color);
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.emoji-picker__search-not-found h2 {
    color: var(--secondary-text-color);
}

.emoji-picker.dark .emoji-picker__search-not-found {
    color: var(--dark-secondary-text-color);
}

.emoji-picker.dark .emoji-picker__search-not-found h2 {
    color: var(--dark-secondary-text-color);
}

.emoji-picker__search-not-found-icon {
    font-size: 3em;
}

.emoji-picker__search-not-found h2 {
    margin: 0.5em 0;
    font-size: 1em;
}

.emoji-picker__variant-overlay {
    background: var(--overlay-background-color);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    animation: fade-in var(--animation-duration) var(--animation-easing);
}

.emoji-picker__variant-overlay.hiding {
    animation: fade-out var(--animation-duration) var(--animation-easing);
}

.emoji-picker__variant-popup {
    background: var(--popup-background-color);
    margin: 0.5em;
    padding: 0.5em;
    text-align: center;
    border-radius: 5px;
    animation: grow var(--animation-duration) var(--animation-easing);
    user-select: none;
}

.emoji-picker__variant-overlay.hiding .emoji-picker__variant-popup {
    animation: shrink var(--animation-duration) var(--animation-easing);
}

.emoji-picker.dark .emoji-picker__variant-popup {
    background: var(--dark-popup-background-color);
}

.emoji-picker__emojis {
    overflow-y: scroll;
    position: relative;
    height: calc((var(--emoji-size) * var(--emoji-size-multiplier)) * var(--row-count) + var(--category-name-size));
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.emoji-picker__emojis.hiding {
    animation: fade-out 0.05s var(--animation-easing);
}

.emoji-picker__emojis h2.emoji-picker__category-name {
    font-size: 0.85em;
    color: var(--secondary-text-color);
    text-transform: uppercase;
    margin: 0.25em 0;
    text-align: left;
}

.emoji-picker.dark h2.emoji-picker__category-name {
    color: var(--dark-secondary-text-color);
}

.emoji-picker__category-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: var(--category-button-height);
    margin-bottom: 0.5em;
}

button.emoji-picker__category-button {
    flex-grow: 1;
    background: transparent;
    padding: 0;
    border: none;
    cursor: pointer;
    font-size: var(--category-button-size);
    vertical-align: middle;
    color: var(--category-button-color);
    border-bottom: var(--category-border-bottom-size) solid transparent;
    outline: none;
}

.emoji-picker.keyboard button.emoji-picker__category-button:focus {
    outline: 1px dotted var(--focus-indicator-color);
}

.emoji-picker.dark button.emoji-picker__category-button.active {
    color: var(--category-button-active-color);
}

.emoji-picker.dark button.emoji-picker__category-button {
    color: var(--dark-category-button-color);
}

button.emoji-picker__category-button.active {
    color: var(--category-button-active-color);
    border-bottom: var(--category-border-bottom-size) solid var(--category-button-active-color);
}

@media (prefers-color-scheme: dark) {
    .emoji-picker.auto {
        background: var(--dark-background-color);
        color: var(--dark-text-color);
        border-color: var(--dark-border-color);
    }

    .emoji-picker.auto .emoji-picker__preview {
        border-top-color: var(--dark-border-color);
    }

    .emoji-picker.auto .emoji-picker__preview-name {
        color: var(--dark-text-color);
    }

    .emoji-picker.auto button.emoji-picker__category-button {
        color: var(--dark-category-button-color);
    }

    .emoji-picker.auto button.emoji-picker__category-button.active {
        color: var(--category-button-active-color);
    }

    .emoji-picker.auto .emoji-picker__emoji:focus, .emoji-picker.auto .emoji-picker__emoji:hover {
        background: var(--dark-hover-color);
    }

    .emoji-picker.auto .emoji-picker__search {
        background: var(--dark-search-background-color);
        color: var(--dark-text-color);
        border-color: var(--dark-search-border-color);
    }

    .emoji-picker.auto h2.emoji-picker__category-name {
        color: var(--dark-secondary-text-color);
    }

    .emoji-picker.auto .emoji-picker__search::placeholder {
        color: var(--dark-search-placeholder-color);
    }

    .emoji-picker.auto .emoji-picker__search:focus {
        border-color: var(--dark-search-focus-border-color);
    }

    .emoji-picker.auto .emoji-picker__search-not-found {
        color: var(--dark-secondary-text-color);
    }

    .emoji-picker.auto .emoji-picker__search-not-found h2 {
        color: var(--dark-secondary-text-color);
    }

    .emoji-picker.auto .emoji-picker__variant-popup {
        background: var(--dark-popup-background-color);
    }
}
